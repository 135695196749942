<template>
    <component :is="dynamicComponent"></component>
</template>

<script>
export default {
    data(){
        return {
            slug: '',

        }
    },
    computed: {
        dynamicComponent(){            
            let fileName = this.$options.filters.capitalize(this.slug);
            return () => import(`../components/Forum/${fileName}`);
        }
    }, 
    created: function(){
        this.slug = this.$route.meta.slug        
        //console.log(this.slug)
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString();
            let strArry = value.split('-')
            if(strArry.length > 1){
                return strArry[0].charAt(0).toUpperCase() + strArry[0].slice(1) + strArry[1].charAt(0).toUpperCase() + strArry[1].slice(1)
            }
            else 
                return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    watch: {
        '$route.meta.slug'(){
            this.slug = this.$route.meta.slug
        },
        '$route.param.categoryid'(){
            this.slug = this.$route.meta.slug
        }
    }, 
}
</script>